import { api } from "@/api";
import { flow, types, SnapshotOut } from "mobx-state-tree";
const MessageItem = types.model("MessageItem", {
  id: types.identifier,
  role: types.string,
  created_at: types.number,
  assistant_id: types.maybeNull(types.string),
  thread_id: types.string,
  message: types.array(types.frozen()),
  metadata: types.frozen(),

});
const CreateMassageJson = types.model("CreateMassageJson", {
  assistant_id: types.string,
  assistant_name: types.string,
  role: types.string,
  content: types.string,
  thread_id: types.string,
  file_ids: types.maybeNull(types.optional(types.array(types.string), [])),
  metaData: types.frozen(),


});
const QueryIur = types.model("QueryIur", {
  id: types.string,
  iur: types.string,
  recall: types.string,
});
export type IMessageItem = SnapshotOut<typeof MessageItem>;
export type ICreateMassageJson = SnapshotOut<typeof CreateMassageJson>;
import { store } from "./index";
export const Message = types
  .model("Message", {
    list: types.array(types.frozen()),
    data: types.frozen(),
    queryIur: types.array(QueryIur),
    type: types.string,
    messageMetadata: types.frozen(),
    replyMessageRole: "all"
  })
  .actions((self) => ({
    setDataType: (val: string) => {
      self.type = val;
    },
  }))
  .actions((self) => {
    const { thread } = store;
    const setList = (list: any[]) => {
      self.list.replace(list as any);
    };
    const setReplyMessageRole = (role: any) => {
      self.replyMessageRole = role;
    };
    const setQueryIur = (value) => {
      self.queryIur = value;
    };
    const setData = (data: any) => {
      self.data = data;
    };
    const setMassgeMetaData = (data: any) => {
      console.log(data);
      self.messageMetadata = data;
    };
    const load = flow(function* (thread_id: string, replyMessageRole: string) {
      const res: ResolvePromiseReturnType<any> =
        yield api.getMessages(thread_id, replyMessageRole);
      const msgList: any = []
      res.data?.messageDTOS.map((item: any) => {
        msgList.push(item)
        if (item?.answers?.length > 0) {
          msgList.push(
            {
              ...item?.answers[0],
              child: item?.answers
            }
          )
        }

      })
      const list = msgList.map((m) => (
        {
          id: m.messageId,
          role: m.role,
          created_at: m.messageRealAt,
          assistant_id: m.assistantId,
          thread_id: m.conversationId,
          message: JSON.parse(m.content)?.content ? JSON.parse(m.content)?.content : JSON.parse(m.content),
          metadata: JSON.parse(m.content)?.metadata ? JSON.parse(m.content)?.metadata : m.metadata,
          roleName: m.role === "customer" ? m.customerName : m.assistantName,
          thumb: m?.thumbs,
          answers: m?.child,
          uid: m?.uid
        }
      ));
      console.log(list)
      // list.reverse();
      if (thread.currentThreadId === thread_id) setList(JSON.parse(JSON.stringify(list)));
      if (list.length > 0) {
        const item = list[list.length - 1];
        // getMessageData(thread_id).then(() => {
        //   thread.setCurrentThreadMetaData(null);
        //   self.setDataType(item.role ?? "");
        // });
        setQueryIur(
          list
            .filter((item) => {
              return item?.role !== "user";
            })
            .map((item) => {
              return {
                id: item.id,
                iur: item?.metadata?.answer_meta?.iur ?? "",
                recall: item?.metadata?.answer_meta?.msg ?? "NO_DOCS_FOUND",
              };
            })
        );
      }
      return list;
    });
    const create = flow(function* (json: ICreateMassageJson) {
      return yield api.createMessage(json);
    });

    const getMessageData = flow(function* (thread_id, message_id) {
      if (message_id === "-10") return;
      const data = yield api.getMessageItemData(thread_id, message_id);


      setData({ metadata: JSON.parse(data?.data.replace(/-Infinity/g, "null").replace(/Infinity/g, "null")) });

    });
    const updateMessageData = flow(function* (data: {
      thread_id;
      message_id;
      thumb;
    }) {
      return yield api.updateMessageItemData(data);
    });

    const setMessageChildId = flow(function* (data: { cmsg_id; pmsg_id }) {
      return yield api.setMessageChildId(data);
    });

    const getMessagesItem = flow(function* (id) {
      return yield api.getMessagesItem(id);
    });

    const messageRetryCreate = flow(function* (data: {
      message_ids;
      thread_id;
      assistant_id;
    }) {
      return yield api.messageRetryCreate(data);
    });
    const messageRetryReply = flow(function* (data: {
      message_id;
    }) {
      return yield api.messageRetryReply(data);
    });
    const getLastMessagePre = flow(function* (id) {
      return yield api.getLastMessagePre(id);
    });
    return {
      load,
      setList,
      create,
      setData,
      getMessageData,
      updateMessageData,
      setMessageChildId,
      getMessagesItem,
      messageRetryCreate,
      setQueryIur,
      messageRetryReply,
      getLastMessagePre,
      setMassgeMetaData,
      setReplyMessageRole
    };
  });
