import { api } from "@/api";
import { types, flow } from "mobx-state-tree";

export const File = types
  .model("File", {
    fileList: types.array(types.frozen()),
  })
  .actions((self) => ({
    setFileList(list) {
      self.fileList.replace(list);
    },
  }))
  .actions((self) => {
    const load = flow(function* () {
      const data = yield loadMaxGet(100);
      return data
    });
    const deleteFile = flow(function* (id: string) {
      yield api.deleteFile(id);
      load();
    });
    const upload = flow(function* (file, metadata, embeddings) {
      const data = yield api.uploadFile(file, metadata, embeddings);
      return data;
    });

    const loadMaxGet = async (pageSize: number) => {
      return api.getFile(pageSize).then((res: any) => {
        if (res.data.length >= pageSize) {
          return loadMaxGet(res.data.length + pageSize);
        } else {
          self.setFileList(res.data);
          return res.data;
        }
      });
    };
    const getFileItem = flow(function* (file_id) {
      const data = yield api.getFileItem(file_id);
      return data;
    });
    const getFileUrl = flow(function* (name) {
      const data = yield api.getFileUrl(name);
      return data;
    });

    return {
      load,
      deleteFile,
      upload,
      getFileItem,
      getFileUrl
    };
  });
