import { useMemo, ReactNode } from "react";
import { observer } from "mobx-react-lite";

import { Tooltip } from "antd";
interface SvgIconProps {
  prefix?: string;
  tip?: string | ReactNode;
  name: string;
  color?: string;
  size?: number | string;
  className?: any;
  onClick?: (e) => void;
}

const SvgIcon = observer((props: SvgIconProps) => {
  const {
    prefix = "icon",
    name,
    color = "",
    size = 16,
    className = "",
    tip = "",
    onClick = (e: any) => {
      e.preventDefault();
    },
  } = props;
  const symbolId = useMemo(() => `#${prefix}-${name}`, [prefix, name]);
  return (
    <div className="icon_gray_bg">
      {className?.includes("iconBg") && (
        <Tooltip title={tip}>
          <div className="icon_gray"></div>
        </Tooltip>
      )}

      <Tooltip title={tip}>
        <svg
          aria-hidden="true"
          className={className}
          width={size}
          height={size}
          fill={color}
          onClick={onClick}
          style={{ color, zIndex: 9999 }}
        >
          <use href={symbolId} fill={color} />
        </svg>
      </Tooltip>
    </div>
  );
});
export default SvgIcon;
